import Link from 'next/link'

import LanguageDropdown from './LanguageDropdown'
import Logo from './Logo'

const LoginHeader = () => {
  return (
    <header id="page-topbar">
      <nav className="navbar-header navbar-dark px-1 px-lg-5">
        <div className="logo-wrapper mr-5">
          <div className="navbar-brand-box">
            <Link href="/">
              <div
                className="logo-sm d-flex align-items-center"
                style={{ width: '130px' }}
              >
                <Logo mode="light" />
              </div>
            </Link>
          </div>
        </div>
        <div className="navbar-nav d-flex justify-content-end">
          <div className="navbar-user-menu pr-2 pr-xl-0">
            <LanguageDropdown />
          </div>
        </div>
      </nav>
    </header>
  )
}
export default LoginHeader
